import React, { useState } from 'react';
import { Box } from '@myob/myob-widgets';
import { RecaptchaDescription } from 'components/recaptchaDescription/RecaptchaDescription';
import { InfoPanel } from '../infoPanel/InfoPanel';
import { ContentPanel } from '../contentPanel/ContentPanel';
import style from './Main.module.scss';

type MainProps = {
  content?: React.ReactNode;
  showProductCard?: boolean;
}
export const Main: React.FC<MainProps> = ({
  children,
  content,
  showProductCard
}) => {
  const [contentPanelFaded, setContentPanelFaded] = useState(false);
  return (
    <Box className={style.main} data-testid='generic-main'>
      <InfoPanel
        contentPanelFaded={contentPanelFaded}
        setContentPanelFaded={setContentPanelFaded}
        showProductCard={showProductCard}
      />
      { children &&
        <ContentPanel faded={contentPanelFaded}>
          {children}
          <RecaptchaDescription />
        </ContentPanel>
      }
      {content}
    </Box>
  );
};

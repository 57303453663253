import React from 'react';
import { Text } from '@myob/myob-widgets';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { FEELIX_THEMES } from '../../constants';
import defaultStyle from './SubscriptionHeading.module.scss';
import soloStyle from './SubscriptionHeading.solo.module.scss';

interface SubscriptionHeadingProps {
  theme: string
}
export const SubscriptionHeading: React.FC<SubscriptionHeadingProps> =
  ({ theme }) => {
    const { productFreePeriod, flowStep } = useSessionContext();
    const { getLabels } = useContentContext();
    const isSoloTheme = theme === FEELIX_THEMES.SOLO;
    const style = isSoloTheme ? soloStyle : defaultStyle;

    if (isSoloTheme && flowStep !== -1) return null;

    const title = (productFreePeriod && productFreePeriod > 0) ?
      getLabels(ELEMENTS.FREE_TRIAL_MESSAGE).replace('%s', productFreePeriod.toString()) :
      getLabels(ELEMENTS.SUBSCRIPTION_START_MESSAGE);

    return (
      <Text
        className={`${style.subscriptionHeading}`}
        data-testid='subscription-heading'
        fontWeight='medium'
        frontSize='md'
      >
        {title}
      </Text>
    );
  };

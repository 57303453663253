import { FEELIX_THEMES } from '../../../constants';

export const getStyleProps = (theme: string) => (
  {
    container: {
      paddingTop: 'sm',
      paddingBottom: 'sm',
      paddingLeft: 'md',
      paddingRight: 'md',
      width: '100%',
      ...(theme === FEELIX_THEMES.MODERN && {
        paddingTop: 'lg',
        paddingLeft: 'lg',
        paddingRight: 'lg',
        paddingBottom: '2xs'
      })
    },
    rowOne: {
      as: 'span',
      display: 'block',
      fontSize: 'xs',
      ...(theme === FEELIX_THEMES.MODERN && {
        fontSize: 'sm',
        marginTop: 'sm'
      })
    },
    rowTwo: {
      level: '2',
      marginBottom: '5xs',
      ...(theme === FEELIX_THEMES.SOLO && {
        marginBottom: '4xs',
        marginTop: '4xs'
      })
    }
  }
);

import React from 'react';
import { Box } from '@myob/myob-widgets';
import { PaymentMode } from './PaymentMode';
import { PAYMENT_METHOD } from '../../constants';
import style from './PaymentForm.module.scss';

type PaymentFormProps = {
  paymentMethod: PAYMENT_METHOD;
  handlePaymentOptionChange: (paymentMethod: PAYMENT_METHOD) => void;
}
export const PaymentForm: React.FC<PaymentFormProps> = (
  {
    children,
    paymentMethod,
    handlePaymentOptionChange
  }
) => (
  <Box className={style.container}>
    <PaymentMode
      paymentMethod={paymentMethod}
      handlePaymentOptionChange={handlePaymentOptionChange}
    />
    {children}
  </Box>
);


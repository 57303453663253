import React, { useState } from 'react';
import {
  Button, Input, MYOBLogo, PageHead
} from '@myob/myob-widgets';
import { useForm, UseFormRegisterReturn } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { TermsConditions } from 'components/terms/Terms';
import { AccountDetailsForm as GenericAccountDetailsForm } from 'components/generic';
import { useSendEventToSegment } from 'hooks/useSendEventToSegment/useSendEventToSegment';
import { RecaptchaDescription } from 'components/recaptchaDescription/RecaptchaDescription';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { trimOnBlur } from 'helpers/trimOnBlur/trimOnBlur';
import { useAccountSetupEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useAddShippingInfoEventGA4, useCheckoutSignInEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import {
  ANALYTICS_ID,
  COOKIE_AJS_ANONYMOUS_ID,
  COOKIE_AJS_USER_ID,
  COOKIE_MYOB_VISITOR_ID,
  GTM_ANALYTICS_CONSTANTS,
  REGULAR_EXPRESSIONS,
  STEP_PATH,
  SUBSCRIPTION_TYPE
} from '../../constants';
import config from '../../Config';
import '../../app/App.scss';

export const AccountDetailsForm: React.FC<{
  pageHeaderText: string
  submitButtonText: string
  isNewUser: boolean
  emailValue: string
}> = ({
  pageHeaderText, submitButtonText, isNewUser, emailValue
}) => {
  const {
    region, promoCode, subscriptionType, promotionEndDate, isAnnualRoute
  } = useSessionContext();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  if (promoCode) {
    params.set('pc', promoCode);
  }

  const [isTermsCheck, setIsTermsCheck] = React.useState(false);
  const [termsErrorMessage, setTermsErrorMessage] = React.useState('');
  const [sendPromotionsCheck, setSendPromotionsCheck] = React.useState(true);
  const [disabled, setDisableValue] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const setTerms = () => {
    setIsTermsCheck(!isTermsCheck);
    setTermsErrorMessage('');
  };
  const setSendPromotions = () => {
    setSendPromotionsCheck(!sendPromotionsCheck);
  };

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onTouched' });

  const emailRegister = register('email', { required: true, pattern: REGULAR_EXPRESSIONS.EMAIL });
  const firstNameRegister = register('firstName', { required: true, pattern: REGULAR_EXPRESSIONS.FIRST_NAME });
  const lastNameRegister = register('lastName', { required: true, pattern: REGULAR_EXPRESSIONS.LAST_NAME });
  const phoneNumberRegister = register('phoneNumber', { required: true, pattern: REGULAR_EXPRESSIONS.PHONE });
  const businessNameRegister = register('businessName', {
    required: true,
    validate: (value: string) =>
      REGULAR_EXPRESSIONS.BUSINESS_NAME.test(value) && REGULAR_EXPRESSIONS.NO_ONLY_SPACE.test(value)
  });

  const handleBlur = (register: UseFormRegisterReturn) => (event: FocusEvent) => trimOnBlur(event, register);

  const { loginAfterLogout } = useAuth0();
  const appState = () => ({ clientInformation: { ...getValues(), sendPromotions: sendPromotionsCheck, isNewUser } });

  const getRedirectUrl = () => {
    const queryString = params.toString() ? `?${params.toString()}` : '';

    if (subscriptionType === SUBSCRIPTION_TYPE.TRIAL) {
      if (isNewUser) {
        return `${config.BUY_BASE_URL}/${region.toLowerCase()}${STEP_PATH.TRIAL.CONFIRMATION}${queryString}`;
      }
      return `${config.BUY_BASE_URL}/${region.toLowerCase()}${STEP_PATH.TRIAL.DUPLICATE}${queryString}`;
    }

    return `${config.BUY_BASE_URL}/${region.toLowerCase()}` +
        `${isAnnualRoute ? STEP_PATH.SUBSCRIBE.PAYMENT_ANNUAL : STEP_PATH.SUBSCRIBE.PAYMENT}${queryString}`;
  };

  const postDirectDebitEvent = () => {
    if (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE && !isTermsCheck) {
      setTermsErrorMessage("Please confirm you've acknowledged the terms of use");
    } else {
      handleSubmit(onSubmit)();
    }
  };
  const {
    businessName, phoneNumber, lastName, firstName
  } = getValues();
  const identifyParamsOld = {
    last_name: lastName,
    first_name: firstName,
    business_name: businessName,
    phone_number: phoneNumber,
    terms_and_conditions: isTermsCheck,
    communication_preference: sendPromotionsCheck
  };

  const anonymousId = Cookies.get(COOKIE_AJS_ANONYMOUS_ID);
  const myobVisitorId = Cookies.get(COOKIE_MYOB_VISITOR_ID);
  const userId = Cookies.get(COOKIE_AJS_USER_ID);

  const identifyParams = {
    userId,
    anonymousId,
    traits: {
      ...identifyParamsOld,
      myobVisitorId
    }
  };

  const trackParams = {
    event: GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP_SEGMENT,
    userId,
    anonymousId,
    properties: {
      myobVisitorId
    }
  };

  useAccountSetupEvent();
  useAccountSetupEvent(identifyParamsOld, isSubmit, GTM_ANALYTICS_CONSTANTS.ACCOUNT_SETUP_SEGMENT);
  useAddShippingInfoEventGA4(isNewUser);
  useCheckoutSignInEventGA4(isNewUser, (isSubmit && subscriptionType === SUBSCRIPTION_TYPE.TRIAL));
  useSendEventToSegment({
    shouldSendSegmentEvent: isSubmit,
    identifyParams,
    trackParams
  });
  const useGeneric = subscriptionType !== SUBSCRIPTION_TYPE.TRIAL;

  const onSubmit = async () => {
    setIsSubmit(true);
    setDisableValue(true);
    const redirectUrl = getRedirectUrl();
    if (isNewUser) {
      const email = getValues('email');
      loginAfterLogout({
        appState: appState(),
        authorizationParams: {
          screen_hint: 'signup',
          ui_hint: 'essentials',
          login_hint: email,
          redirect_uri: redirectUrl
        }
      });
    } else {
      loginAfterLogout({
        appState: appState(),
        authorizationParams: {
          login_hint: emailValue,
          redirect_uri: redirectUrl
        }
      });
    }
  };

  if (useGeneric) {
    return (
      <GenericAccountDetailsForm
        emailValue={emailValue}
        disabled={disabled}
        handleSubmit={postDirectDebitEvent}
        // todo: get rid of submitButtonText prop
        submitButtonText={submitButtonText}
        isTermsCheck={isTermsCheck}
        termsErrorMessage={termsErrorMessage}
        setTerms={setTerms}
        sendPromotionsCheck={sendPromotionsCheck}
        setSendPromotions={setSendPromotions}
        register={register}
        errors={errors}
      />
    );
  }

  return (
    <div data-testid='account-details-form' className='feature-box-right'>
      <div style={{ width: '6.25em', marginBottom: '1em' }}>
        <MYOBLogo />
      </div>
      <PageHead title={pageHeaderText}/>
      <div style={{
        width: '20em',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start'
      }}
      >
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_EMAIL}
          label='Email*'
          value={emailValue}
          {...{ ...emailRegister, reference: emailRegister.ref }}
          disabled
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_FIRST_NAME}
          label='First name*'
          {...{ ...firstNameRegister, reference: firstNameRegister.ref }}
          onBlur={handleBlur(firstNameRegister)}
          errorMessage={errors.firstName && (errors.firstName.type === 'required' ?
                'First name is required' : 'First name is invalid')}
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_LAST_NAME}
          label='Last name*'
          {...{ ...lastNameRegister, reference: lastNameRegister.ref }}
          onBlur={handleBlur(lastNameRegister)}
          errorMessage={errors.lastName && (errors.lastName.type === 'required' ?
                'Last name is required' : 'Last name is invalid')}
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_PHONE_NUMBER}
          label='Phone number*'
          {...{ ...phoneNumberRegister, reference: phoneNumberRegister.ref }}
          onBlur={handleBlur(phoneNumberRegister)}
          inputMode='tel'
          errorMessage={ errors.phoneNumber && (errors.phoneNumber.type === 'required' ?
                'Phone number is required' : 'Phone number is invalid')}
        />
        <Input
          className={'form-input'}
          id={ANALYTICS_ID.INPUT_BUSINESS_NAME}
          label='Business name*'
          {...{ ...businessNameRegister, reference: businessNameRegister.ref }}
          onBlur={handleBlur(businessNameRegister)}
          errorMessage={ errors.businessName && (errors.businessName.type === 'required' ?
                'Business name is required' : 'Business name is invalid')}
        />
        <Button
          id={ANALYTICS_ID.BUTTON_LOGIN}
          type='primary'
          onClick={postDirectDebitEvent}
          disabled={disabled}
        >
          {submitButtonText}
        </Button>
        {promoCode && promotionEndDate && <TermsConditions className='terms'/> }
      </div>
      <RecaptchaDescription />
    </div>
  );
};

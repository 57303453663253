import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  ButtonLink,
  ErrorIcon,
  Input,
  Spinner,
  Text,
  TickIcon
} from '@myob/myob-widgets';
import { useProductData } from 'hooks/useProductData/useProductData';
import { trackClickEvent } from 'hooks/GTM4Analytics/useGTMAnalytics';
import usePromoCodeBoxState from 'hooks/usePromoCodeBoxState/usePromoCodeBoxState';
import {
  GTM4_ANALYTICS_CONSTANTS, ANALYTICS_ID, PROMO_CODE_STATE, FEELIX_THEMES
} from '../../constants';
import defaultStyle from './PromoCodeBox.module.scss';
import soloStyle from './PromoCodeBox.solo.module.scss';
import { useSessionContext } from '../../contexts/sessionProvider/SessionProvider';

const TICK_ICON = {
  [PROMO_CODE_STATE.VALID]: <TickIcon className='promo-code-input-tick-icon'/>,
  [PROMO_CODE_STATE.INVALID]: <ErrorIcon className='promo-code-input-error-icon'/>
};

export const PromoCodeBox = (): React.ReactElement => {
  const { productData } = useProductData();
  const productItems = productData?.items;
  const promoCode = productItems[0].deal?.promoCode ?? '';
  const {
    isLoading: isVerifying,
    state: promoCodeState,
    clearState,
    updatePromoCodeBoxState
  } = usePromoCodeBoxState();
  const { theme } = useSessionContext();
  const currentStyle = theme === FEELIX_THEMES.SOLO ? soloStyle : defaultStyle;

  const [showPromoBox, setShowPromoBox] = useState(false);
  const { register, handleSubmit } = useForm({ mode: 'onTouched' });
  const formRegister = register('promoCode', {
    required: true, validate: (value: string) => value.trim() !== ''
  });

  const onSubmit = handleSubmit((data) => {
    const { promoCode } = data;
    // using css-transform to display in capitals
    // hence transforming at form-submit rather at onchange to improve performance
    const upperCasePromoCode = promoCode.toUpperCase();
    trackClickEvent(GTM4_ANALYTICS_CONSTANTS.PROMO_CODE_APPLY_BUTTON_CLICK, { promo_code: upperCasePromoCode });
    updatePromoCodeBoxState(upperCasePromoCode);
  });

  if (showPromoBox) {
    return (
      <form id='promo-code-form' onSubmit={onSubmit}>
        <Box
          className={currentStyle.promoCodeGroup}
          data-testid='promo-code-group'
        >
          <Text id='promo-code-input-label' for='promo-code-input-form'
            className={`${currentStyle.promoCodeInputLabel}`}
            as='span' fontSize='sm'
          >Apply promotional code</Text>
          <Box
            className={`${currentStyle.promoCodeInputText} ${currentStyle.promoCodeInputText}`}
          >
            <Input
              className={`${currentStyle.promoCodeInputBox} ${currentStyle[promoCodeState.toLowerCase()]}`}
              data-testid='promo-code-input'
              disabled={isVerifying}
              containerClassName={`${currentStyle.promoCodeInputContainer}`}
              id='promo-code-input-form'
              hideLabel='hide-label'
              autocomplete='off'
              autocapitalize='characters'
              autocorrect='off'
              spellcheck='false'
              aria-labelledby='promo-code-input-label'
              defaultValue={promoCode}
              suffixIcon={TICK_ICON[promoCodeState]}
              {...{ ...formRegister, reference: formRegister.ref }}
              onChange={clearState}
              {...(promoCodeState !== PROMO_CODE_STATE.EMPTY && {
                errorVariant: promoCodeState === PROMO_CODE_STATE.VALID ? 'success' : 'danger',
                errorMessage: promoCodeState === PROMO_CODE_STATE.VALID ?
                  'Promotional code applied' : 'Invalid promotional code'
              })}
            />
            {isVerifying ? (
              <Button
                className={`${currentStyle.promoCodeApplyButton}`}
                data-testid='promo-code-verifying-button' disabled={true}
              >
                <Spinner isLoading={true} size={'small'} tone={'neutral'}/>
              </Button>
            ) : (
              <Button
                className={`${currentStyle.promoCodeApplyButton}`}
                data-testid='promo-code-apply-button'
                onClick={onSubmit}
              >
                <p
                  className={`${currentStyle.promoCodeApplyButtonText}`}
                >Apply</p>
              </Button>
            )}
          </Box>
        </Box>
      </form>
    );
  }
  return (
    <div>
      <ButtonLink inline='true' className={'promo-code-link'} onClick={() => setShowPromoBox(true)}>
        <a id={ANALYTICS_ID.LINK_HAVE_PROMO_CODE}>Have a promotional code?</a>
      </ButtonLink>
    </div>
  );
};

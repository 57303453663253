import React from 'react';
import { Redirect } from 'react-router-dom';
import { Confirmation as GenericConfirmation } from 'components/generic';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import { useAuth0 } from 'hooks/Auth0/Auth0';
import { useContentViewed, useSendPurchaseEventAndOrderCompletedEvent } from 'hooks/Analytics/useAnalytics';
import { useEnhancedPurchaseEvent, usePurchaseEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { useContactCreation } from 'hooks/useContactsCreation/useContactCreation';
import { useAccountCreation } from 'hooks/useAccountsCreation/useAccountCreation';
import { useTrialCreation } from 'hooks/useTrialCreation/useTrialCreation';
import { usePurchaseEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import {
  ANALYTICS_ID,
  GTM_ANALYTICS_CONSTANTS,
  NINETAILED_ANALYTICS_CONSTANTS
} from '../../../constants';
import { useSendEventToSegmentForPurchase } from '../useSendEventToSegmentForPurchase';
import '../Confirmation.scss';

function trackNewTrialConfirmationPage(email?: string) {
  /* eslint-disable react-hooks/rules-of-hooks */
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_TRIAL_CONFIRMATION
  });
  useSendPurchaseEventAndOrderCompletedEvent();
  usePurchaseEventGA4();
  usePurchaseEvent(GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT);
  useSendEventToSegmentForPurchase();
  useEnhancedPurchaseEvent(email);
  /* eslint-enable react-hooks/rules-of-hooks */
}

const NewTrialConfirmation: React.FC = () => {
  const { user: { email } = {}, isLoading: isAuth0Loading, isAuthenticated } = useAuth0();
  const { isLoading: isContactLoading, isError: isContactError, contactDetails } = useContactCreation();
  const { isLoading: isAccountLoading, isError: isAccountError, accountDetails } =
      useAccountCreation(contactDetails?.id);
  const {
    isLoading: isTrialLoading,
    isError: isTrialError
  } = useTrialCreation(accountDetails?.id);

  const isApiRequestLoading = isContactLoading || isAccountLoading || isTrialLoading;
  const isApiRequestError = isContactError || isAccountError || isTrialError;
  trackNewTrialConfirmationPage(email);
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.PURCHASE);

  const isLoading = isAuth0Loading || isApiRequestLoading;
  if (!isLoading && (!isAuthenticated || isApiRequestError)) {
    return <Redirect to={'/error'}/>;
  }

  return <GenericConfirmation isLoading={isLoading}/>;
};

export default NewTrialConfirmation;

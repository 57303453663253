import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useGetProduct } from 'hooks/useGetProduct/useGetProduct';
import { SUBSCRIPTION_TYPE } from '../../constants';

export const usePromoCodeBoxEnabled = (): boolean => {
  const { subscriptionType } = useSessionContext();
  const { isSuccess, isLoading, productData } = useGetProduct();
  if (!isSuccess || isLoading) {
    return false;
  }
  const isPromoCodeBoxApplicable =
    (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE &&
      productData.items[0]?.deal?.isPromoCodeBoxApplicable) || false;

  return isSuccess && isPromoCodeBoxApplicable;
};

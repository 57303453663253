import React from 'react';
import { Input } from '@myob/myob-widgets';
import { DeepMap, FieldError } from 'react-hook-form';
import { trimOnBlur } from 'helpers/trimOnBlur/trimOnBlur';
import { REGULAR_EXPRESSIONS } from '../../../constants';
import { DirectDebitDetail } from '../types';
import './directDebitForm.scss';

export const DirectDebitContainerNZ: React.FC<{
  register:any,
  errors:DeepMap<DirectDebitDetail, FieldError>,
}> = ({
  register,
  errors
}) => {
  const bankRegister = register('bank', { required: true, pattern: REGULAR_EXPRESSIONS.BANK });
  const branchRegister = register('branch', { required: true, pattern: REGULAR_EXPRESSIONS.BRANCH });
  const accountRegister = register('account', { required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT });
  const suffixRegister = register('suffix', { required: true, pattern: REGULAR_EXPRESSIONS.SUFFIX });
  const accountNameRegister = register('accountName', { required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NAME });

  return (
    <>
      <Input
        className={'input__field'}
        id='bank'
        label='Bank*'
        {...{ ...bankRegister, reference: bankRegister.ref }}
        errorMessage={errors.bank && (errors.bank.type === 'required' ?
            'Bank ID is required' : 'Please enter a valid bank ID')}
      />
      <Input
        className={'input__field'}
        id='branch'
        label='Branch*'
        {...{ ...branchRegister, reference: branchRegister.ref }}
        errorMessage={errors.branch && (errors.branch.type === 'required' ?
              'Branch number is required' : 'Please enter a valid branch number')}
      />
      <Input
        className={'input__field'}
        id='account'
        label='Account*'
        {...{ ...accountRegister, reference: accountRegister.ref }}
        errorMessage={errors.account && (errors.account.type === 'required' ?
            'Account number is required' : 'Please enter a valid account number')}
      />
      <Input
        className={'input__field'}
        id='suffix'
        label='Suffix*'
        {...{ ...suffixRegister, reference: suffixRegister.ref }}
        errorMessage={errors.suffix && (errors.suffix.type === 'required' ?
            'Suffix is required' : 'Please enter a valid suffix')}
      />
      <Input
        className={'input__field'}
        id='accountName'
        label='Account Name*'
        {...{ ...accountNameRegister, reference: accountNameRegister.ref }}
        onBlur={(event: FocusEvent) => trimOnBlur(event, accountNameRegister)}
        errorMessage={errors.accountName && (errors.accountName.type === 'required' ?
            'Account name is required' : 'Please enter a valid account name')}
      />
      <br />
    </>
  );
};

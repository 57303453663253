import React from 'react';
import { Box } from '@myob/myob-widgets';
import { ProgressBar } from 'components/progressBar/ProgressBar';
import { MYOBLogo, SoloLogo } from 'components/logo';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import style from './ContentPanel.module.scss';
import { FEELIX_THEMES } from '../../constants';

type RightPanelProps = {
  faded: boolean;
  contentClassName?: string;
}
export const ContentPanel: React.FC<RightPanelProps> = ({
  children, faded, contentClassName
}) => {
  const { flowStep, theme } = useSessionContext();
  const isSoloTheme = theme === FEELIX_THEMES.SOLO;

  return (
    <Box className={[style.contentPanel, {
      [style.contentPanelFaded]: faded,
      [style.solo]: flowStep === -1,
      [style.default]: !isSoloTheme
    }]}
    >
      <Box
        className={[style.contentPanelContent, {
          [contentClassName || '']: Boolean(contentClassName),
          [style.defaultContentPanelContent]: !isSoloTheme
        }]}
      >
        <SoloLogo/>
        <ProgressBar/>
        <MYOBLogo theme={theme}/>
        {children}
      </Box>
    </Box>
  );
};

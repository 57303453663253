import { FEELIX_THEMES } from '../../../constants';

export const getStyleProps = (theme: string) => (
  {
    footer: {
      paddingTop: 'clear',
      paddingBottom: 'clear',
      paddingLeft: 'md',
      paddingRight: 'md',
      ...(theme === FEELIX_THEMES.MODERN && {
        paddingLeft: 'lg',
        paddingRight: 'lg'
      })
    },
    separator: {
      display: 'none',
      ...(theme === FEELIX_THEMES.MODERN && {
        display: 'block',
        marginTop: 'clear',
        marginBottom: 'xl'
      })
    }
  }
);

import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'components/button/Button';
import { Redirect } from 'react-router-dom';
import { keys } from 'lodash';
import PaymentTerms from 'components/paymentTerms/PaymentTerms';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useCreditCardValidator } from 'hooks/useCreditCardValidator/useCreditCardValidator';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { CreditCardDetail, PCIPortholeData } from 'components/paymentPage/types';
import {
  COLOURS_AS_HEX, PAYMENT_TERMS_ERROR_MESSAGE, WESTPAC, WESTPAC_AFFILIATED
} from '../../../constants';
import config from '../../../Config';

export const CreditCardForm: React.FC<{
  isPaymentProfileCreationLoading: boolean,
  setCreditCardDetail: React.Dispatch<React.SetStateAction<CreditCardDetail>>,
  createPaymentProfile: () => void
}> = ({ isPaymentProfileCreationLoading, setCreditCardDetail, createPaymentProfile }) => {
  const PROCESS_CREDIT_CARD_ACTION = 'processCreditCard';
  const { promoCode } = useSessionContext();

  const [isTermsCheck, setIsTermsCheck] = React.useState(false);

  const [termsErrorMessage, setTermsErrorMessage] = React.useState('');

  const postProcessCreditCardEvent = () => {
    if (isTermsCheck) {
      const event = { action: PROCESS_CREDIT_CARD_ACTION };
      window.cardIframeProxy.post(event);
    } else {
      setTermsErrorMessage(PAYMENT_TERMS_ERROR_MESSAGE);
    }
  };

  const [firstSixDigits, setFirstSixDigits] = useState('');
  const [isValidCreditCard, setIsValidCreditCard] = useState(false);
  const [showWestpacCardError, setShowWestpacCardError] = useState(false);
  const { getLabels } = useContentContext();

  const {
    isLoading: isCreditCardValidationLoading,
    isError: isCreditCardValidationError,
    refetch
  } = useCreditCardValidator(firstSixDigits, promoCode);

  useEffect(() => {
    if (isValidCreditCard) {
      createPaymentProfile();
    }
  }, [isValidCreditCard]);

  const handleCardPortalMessage = useCallback((ev: WindowEventMap['message']): void => {
    if (config.PAYMENT_PCI_URL.startsWith(ev.origin)) {
      const event = ev.data as PCIPortholeData;
      if (event.outcome.validation.valid) {
        const firstSixDigits = event.outcome.cardDetails.maskedNumber.slice(0, 6);
        setCreditCardDetail(event.outcome.cardDetails);
        setFirstSixDigits(firstSixDigits);
      }
    }
  }, []);

  const checkCard = () =>
    refetch().then((result) => result.data?.data.validFirstSixDigits);

  useEffect(() => {
    if (firstSixDigits) {
      if (promoCode && Object.keys(WESTPAC).includes(promoCode)) {
        checkCard().then((isValidWestpacCard) => {
          setIsValidCreditCard(isValidWestpacCard);
          setShowWestpacCardError(!isValidWestpacCard);
        });
      } else {
        setIsValidCreditCard(true);
      }
    }
  }, [firstSixDigits]);

  const setTerms = () => {
    setIsTermsCheck(!isTermsCheck);
    setTermsErrorMessage('');
  };

  useEffect(() => {
    window.cardIframeProxy = new Porthole.WindowProxy(config.CARD_PORTAL_PROXY_URL, 'cardPortalFrame');

    window.cardIframeProxy.addEventListener(handleCardPortalMessage);

    return (): void => {
      window.cardIframeProxy.removeEventListener(handleCardPortalMessage);
    };
  }, [handleCardPortalMessage]);

  if (isCreditCardValidationError) {
    return <Redirect to='/error'/>;
  }

  return (
    <div style={{
      width: '25em', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'
    }}
    >
      <iframe
        data-testid='cardPortalFrame'
        id='cardPortalFrame'
        style={{ minHeight: '454px', border: 0, overflow: 'hidden' }}
        sandbox='allow-same-origin allow-scripts allow-popups'
        name='cardPortalFrame'
        src={config.PAYMENT_PCI_URL}
        scrolling='no' // Can't remove, cuz dest page overflow. https://stackoverflow.com/questions/15494568
        seamless
        title='cardPortalFrame'
      />
      {showWestpacCardError && <WestpacCardError promoCode={promoCode}/>}
      <PaymentTerms
        isChecked={isTermsCheck}
        errorMessage={termsErrorMessage} setTerms={setTerms}
      />
      <Button
        onClick={postProcessCreditCardEvent}
        disabled={isPaymentProfileCreationLoading || isCreditCardValidationLoading}
      >
        {getLabels(ELEMENTS.PAYMENT_FORM_SUBMIT_BUTTON)}
      </Button>
    </div>
  );
};

const WestpacCardError: React.FC<{ promoCode: string | undefined }> = ({ promoCode }) =>
  <div style={{ width: '28em', color: COLOURS_AS_HEX.Berry, marginBottom: '2em' }}>
    {promoCode && keys(WESTPAC_AFFILIATED).includes(promoCode) ?
      <span>
        Please add a valid St.George Bank, Bank of Melbourne or Bank SA business debit or credit card or
        business transaction account.
      </span> :
      <span>
        Please add a valid Westpac business debit or credit card or business transaction account.
      </span>
    }
  </div>;

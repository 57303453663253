type DeepLinking = {
  BUTTON_DISABLE_TIME: number;
}

type Config = {
  ENV?: string;
  MYOB_ID_DOMAIN: string;
  CHECKOUT_AUTH0_CLIENT_ID: string;
  CHECKOUT_AUTH0_CLIENT_SOLO_ORGANIZATION: string;
  API_BASE_URL: string;
  API_EXISTING_USER_CHECK: string;
  API_PRODUCT_PRICE_ENDPOINT: string;
  API_CONTENT_ENDPOINT: string;
  API_CHECKOUT_ENDPOINT_AUTH0: string;
  API_PURCHASE_ENDPOINT: string;
  API_CHECKOUT_ENDPOINT: string;
  API_ANNUAL_PURCHASE_ENDPOINT: string;
  API_TRIAL_ENDPOINT: string;
  API_CONTACTS_ENDPOINT: string;
  API_ACCOUNTS_ENDPOINT: string;
  API_PAYMENT_PROFILE_ENDPOINT: string;
  API_VALIDATE_BANK_CODE: string;
  API_RECAPTCHA_ENDPOINT: string;
  API_CREDIT_CARD_VALIDATION_ENDPOINT: string;
  BUY_BASE_URL: string;
  PRODUCT_URL: string;
  PAYMENT_PCI_URL: string;
  CARD_PORTAL_PROXY_URL: string;
  BUY_BASE_HOST: string;
  WEBSITE_NZ: string;
  ONLINE_INVOICE_PAYMENTS: string;
  PAYROLL_ONLY: string;
  PREMIUM_FEATURES: string;
  PREMIUM_INVENTORY: string;
  FAIR_USE_POLICY_NZ: string;
  DEEP_LINKING: DeepLinking;
  SEGMENT_EVENT_ENDPOINT: string;
  NINETAILED_ENVIRONMENT: string;
  SENTRY_ENABLED: boolean;
  SENTRY_DSN: string;
}

const configuration = {
  default: {
    BUY_BASE_HOST: 'buy.myob.com',
    API_BASE_URL: 'https://dxp-bettacart-bff-production.svc.platform.myob.com',
    BUY_BASE_URL: 'https://buy.myob.com',
    API_EXISTING_USER_CHECK: '/existing-user',
    API_PRODUCT_PRICE_ENDPOINT: '/product-price',
    API_CONTACTS_ENDPOINT: '/contacts',
    API_PURCHASE_ENDPOINT: '/purchase',
    API_CHECKOUT_ENDPOINT: '/checkout-generic',
    API_CONTENT_ENDPOINT: '/content',
    // this is v2 ui connecting to v1 backend (workaround for annual purchase)
    API_ANNUAL_PURCHASE_ENDPOINT: '/checkout/annual/auth',
    API_TRIAL_ENDPOINT: '/trial',
    SEGMENT_EVENT_ENDPOINT: '/segment',
    API_ACCOUNTS_ENDPOINT: '/accounts',
    API_CHECKOUT_ENDPOINT_AUTH0: '/checkout/auth0',
    API_RECAPTCHA_ENDPOINT: '/recaptcha',
    API_PAYMENT_PROFILE_ENDPOINT: '/payment-profiles',
    API_VALIDATE_BANK_CODE: '/validate-bank-code',
    API_CREDIT_CARD_VALIDATION_ENDPOINT: '/validate-credit-card',
    CHECKOUT_AUTH0_CLIENT_ID: 'o0yogyeuy1iXoIhZoI2KyTXCplXC8mLy',
    CHECKOUT_AUTH0_CLIENT_SOLO_ORGANIZATION: 'org_yRd73qlIFnoIhILh',
    PRODUCT_URL: 'https://app.myob.com/?ig#/businesses',
    PAYMENT_PCI_URL: 'https://pcicardportal.myob.com/portals/bettacartui/',
    CARD_PORTAL_PROXY_URL: 'https://pcicardportal.myob.com/proxy.html',
    MYOB_ID_DOMAIN: 'id.myob.com',
    WEBSITE_NZ: 'https://www.myob.com/nz',
    ONLINE_INVOICE_PAYMENTS: 'https://help.myob.com/wiki/display/myob/Online+invoice+payments#expand-IminAustralia',
    PAYROLL_ONLY: 'https://help.myob.com/wiki/display/myob/Payroll+limits+and+fees',
    PREMIUM_FEATURES: 'https://help.myob.com/wiki/display/GEN/MYOB+Premium+Features',
    PREMIUM_INVENTORY: 'https://help.myob.com/wiki/display/myob/Add+Premium+Inventory',
    FAIR_USE_POLICY_NZ: 'https://www.myob.com/nz/accounting-software/bankfeeds/myob-bankfeeds-fair-use-policy',
    DEEP_LINKING: {
      BUTTON_DISABLE_TIME: 5000
    },
    NINETAILED_ENVIRONMENT: 'development',
    SENTRY_ENABLED: false,
    // not a vulnerability
    // eslint-disable-next-line max-len
    // https://sentry.zendesk.com/hc/en-us/articles/26741783759899-My-DSN-key-is-publicly-visible-is-this-a-security-vulnerability
    SENTRY_DSN: 'https://86fb7d26e55c6d7fd332795b05bb9a9f@o39702.ingest.us.sentry.io/4508057248530432'
  },
  local: {
    API_BASE_URL: 'https://dxp-bettacart-bff-sit.svc.platform.myobdev.com',
    BUY_BASE_URL: 'http://localhost:3000',
    BUY_BASE_HOST: 'localhost',
    PAYMENT_PCI_URL: 'https://sit.pcicardportal.myob.com/portals/bettacartui/',
    PRODUCT_URL: 'https://pdv-sme-web.app.myob.com/?ig#/businesses',
    CARD_PORTAL_PROXY_URL: 'https://sit.pcicardportal.myob.com/proxy.html',
    MYOB_ID_DOMAIN: 'sit.id.myob.com'
  },
  sit: {
    BUY_BASE_URL: 'https://sit.buy.myob.com',
    BUY_BASE_HOST: 'sit.buy.myob.com',
    API_BASE_URL: 'https://dxp-bettacart-bff-sit.svc.platform.myobdev.com',
    PRODUCT_URL: 'https://pdv-sme-web.app.myob.com/?ig#/businesses',
    PAYMENT_PCI_URL: 'https://sit.pcicardportal.myob.com/portals/bettacartui/',
    CARD_PORTAL_PROXY_URL: 'https://sit.pcicardportal.myob.com/proxy.html',
    MYOB_ID_DOMAIN: 'sit.id.myob.com'
  },
  production: {
    CHECKOUT_AUTH0_CLIENT_SOLO_ORGANIZATION: 'org_rQWG87QFrqbBOEGf',
    CHECKOUT_AUTH0_CLIENT_ID: 'XjafHfncbwGQIyrto053V6WLKocNfSvr',
    NINETAILED_ENVIRONMENT: 'main',
    SENTRY_ENABLED: true
  }
};

const config: Config =
 Object.assign(configuration.default, configuration[process.env.REACT_APP_STAGE]);

export default config;

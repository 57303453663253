import React from 'react';
import {
  BankIcon,
  Box,
  CreditCardIcon,
  RadioButton,
  SelectPill,
  SelectPillGroup,
  TickIcon,
} from '@myob/myob-widgets';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { FEELIX_THEMES, PAYMENT_METHOD } from '../../constants';
import style from './PaymentForm.module.scss';

type PaymentModeProps = {
  paymentMethod: PAYMENT_METHOD;
  handlePaymentOptionChange: (paymentMethod: PAYMENT_METHOD) => void;
};
const PaymentSelector = (
  { paymentMethod, handlePaymentOptionChange, checked }: PaymentModeProps & { checked: boolean }
) => (
  <SelectPill
    prefixAccessory={checked && (paymentMethod === PAYMENT_METHOD.CREDIT ? <CreditCardIcon/> : <BankIcon/>)}
    suffixAccessory={checked && <TickIcon/>}
    label={paymentMethod}
    name={paymentMethod}
    value={paymentMethod}
    onChange={() => handlePaymentOptionChange(paymentMethod)}
    checked={checked}
  />
);

const SoloPaymentMode: React.FC<PaymentModeProps> = (
  { paymentMethod, handlePaymentOptionChange }
) => (
  <SelectPillGroup label='Payment method' selectionType='single'>
    <PaymentSelector
      paymentMethod={PAYMENT_METHOD.CREDIT}
      handlePaymentOptionChange={handlePaymentOptionChange}
      checked={paymentMethod === PAYMENT_METHOD.CREDIT}
    />
    <PaymentSelector
      paymentMethod={PAYMENT_METHOD.DEBIT}
      handlePaymentOptionChange={handlePaymentOptionChange}
      checked={paymentMethod === PAYMENT_METHOD.DEBIT}
    />
  </SelectPillGroup>
);

const DefaultPaymentMode: React.FC<PaymentModeProps> = (
  { paymentMethod, handlePaymentOptionChange }
) => (
  <Box className={style.defaultPaymentModeContainer}>
    <RadioButton
      value={PAYMENT_METHOD.CREDIT}
      checked={paymentMethod === PAYMENT_METHOD.CREDIT}
      label='Credit Card'
      onChange={handlePaymentOptionChange.bind(null, PAYMENT_METHOD.CREDIT)}
    />
    <RadioButton
      value={PAYMENT_METHOD.DEBIT}
      checked={paymentMethod === PAYMENT_METHOD.DEBIT}
      label='Direct Debit'
      onChange={handlePaymentOptionChange.bind(null, PAYMENT_METHOD.DEBIT)}
    />
  </Box>
);

const getComponent = (theme: string) => {
  switch (theme) {
    case FEELIX_THEMES.SOLO: return SoloPaymentMode;
    default: return DefaultPaymentMode;
  }
};

export const PaymentMode: React.FC<PaymentModeProps> = (props) => {
  const { theme } = useSessionContext();
  const Component = getComponent(theme);
  return <Component {...props}/>;
};


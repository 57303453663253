import React from 'react';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { Main } from 'components/main/Main';
import { SoloConfirmation } from './solo/SoloConfirmation';
import { DefaultConfirmation } from './default/DefaultConfirmation';
import { ConfirmationProps } from './types';
import { FEELIX_THEMES } from '../../../constants';

export const Confirmation: React.FC<ConfirmationProps> = (props) => {
  const { theme } = useSessionContext();
  const content = theme === FEELIX_THEMES.SOLO ? <SoloConfirmation { ...props }/> : null;
  const child = theme === FEELIX_THEMES.SOLO ? null : <DefaultConfirmation { ...props }/>;
  return (
    <Main
      showProductCard={theme !== FEELIX_THEMES.SOLO}
      content={content}
    >
      {child}
    </Main>
  );
};

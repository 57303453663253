import React from 'react';
import { Input, Spinner } from '@myob/myob-widgets';
import { DeepMap, FieldError } from 'react-hook-form';
import { Redirect } from 'react-router-dom';
import { keys } from 'lodash';
import { trimOnBlur } from 'helpers/trimOnBlur/trimOnBlur';
import {
  REGULAR_EXPRESSIONS, WESTPAC, WESTPAC_AFFILIATED, WESTPAC_AFFILIATED_BSB, WESTPAC_BSB
} from '../../../constants';
import { DirectDebitDetail } from '../types';
import './directDebitForm.scss';

export const DirectDebitContainerAU: React.FC<{
  register:any,
  errors:DeepMap<DirectDebitDetail, FieldError>,
  validateBankCode: (bankCode: string) => Promise<boolean>,
  promoCode: string | undefined,
  isValidateBankCodeError: boolean,
  isValidateBankCodeLoading: boolean,
}> = ({
  register,
  errors,
  validateBankCode,
  promoCode,
  isValidateBankCodeError,
  isValidateBankCodeLoading
}) => {
  if (isValidateBankCodeError) {
    return <Redirect to='/error'/>;
  }

  const accountNameRegister = register('accountName', { required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NAME });
  const accountNumberRegister = register('accountNumber', {
    required: true, pattern: REGULAR_EXPRESSIONS.ACCOUNT_NUMBER
  });
  const bsbRegister = register('bsb', {
    required: true,
    pattern: REGULAR_EXPRESSIONS.BSB,
    validate: {
      invalidBsb: validateBankCode,
      invalidWestpac: (bankCode: string) => (promoCode ?
        (!keys(WESTPAC).includes(promoCode) || keys(WESTPAC_AFFILIATED).includes(promoCode)) ||
                WESTPAC_BSB.includes(bankCode.slice(0, 2)) : true),
      invalidAffiliatedBanksOfWestpac: (bankCode: string) => (promoCode ?
        (!keys(WESTPAC_AFFILIATED).includes(promoCode)) || WESTPAC_AFFILIATED_BSB.includes(bankCode.slice(0, 2)) :
        true)
    }
  });

  return (
    <>
      <Input
        className={'input__field'}
        id='bsb'
        label='BSB number*'
        maxLength={6}
        inputMode='numeric'
        {...{ ...bsbRegister, reference: bsbRegister.ref }}
        errorMessage={ errors.bsb && generateBsbNumberErrorMsg(errors.bsb.type) }
      />
      {isValidateBankCodeLoading &&
        <div id='bsb-loading-state' className='flex mt-min'>
          <Spinner data-testid='spinner1' size='small'/>
          <small className='ml-min loading-font'>Validating BSB number...</small>
        </div>
      }
      <Input
        className={'input__field'}
        id='accountNumber'
        label='Account Number*'
        inputMode='numeric'
        {...{ ...accountNumberRegister, reference: accountNumberRegister.ref }}
        errorMessage={errors.accountNumber && (errors.accountNumber.type === 'required' ?
            'Account number is required' : 'Invalid account number')}
      />
      <Input
        className={'input__field'}
        id='accountName'
        label='Account Name*'
        {...{ ...accountNameRegister, reference: accountNameRegister.ref }}
        onBlur={(event: FocusEvent) => trimOnBlur(event, accountNameRegister)}
        errorMessage={errors.accountName && (errors.accountName.type === 'required' ?
            'Account name is required' : 'Please enter a valid account name')}
      />
      <br />
    </>
  );
};

const generateBsbNumberErrorMsg = (errorType: string): string => {
  switch (errorType) {
    case 'required': return 'BSB is required';
    case 'invalidWestpac': return 'Please add a valid Westpac business debit or ' +
      'credit card or business transaction account.';
    case 'invalidAffiliatedBanksOfWestpac': return 'Please add a valid St.George Bank, Bank of Melbourne or ' +
      'Bank SA business debit or credit card or business transaction account.';
    default: return 'Invalid BSB';
  }
};

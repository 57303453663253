import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Main } from 'common/main/Main';
import { SubscribeConfirmation } from 'components/confirmation/subscribeConfirmation/SubscribeConfirmation';
import { MobileAppStore } from 'components/mobileAppStore/MobileAppStore';
import { EmailPage } from 'components/emailPage/EmailPage';
import { PaymentPage } from 'components/paymentPage/PaymentPage';
import DuplicateTrial from 'components/duplicateTrial/DuplidateTrial';
import { ErrorPage } from 'components/errorPage/ErrorPage';
import { NewTrialEmailPage } from 'components/NewTrialEmailPage/NewTrialEmailPage';
import NewTrialConfirmation from 'components/confirmation/newTrialConfirmation/NewTrialConfirmation';
import { TrialConfirmation }
  from 'components/confirmation/trialConfirmation/TrialConfirmation';
import { useRecaptchaBadge } from 'hooks/useRecaptcha/useRecaptchaBadge';

const App: React.FC = () => {
  useRecaptchaBadge();
  return (
    <>
      <Switch>
        <Route path='/:region/subscribe/confirmation'>
          <SubscribeConfirmation/>
        </Route>
        <Route path='/:region/new-trial/confirmation'>
          <NewTrialConfirmation/>
        </Route>
        <Route path='/:region/:product/mobile-app'>
          <MobileAppStore/>
        </Route>
        <Route path='/error' exact>
          <ErrorPage />
        </Route>
        <Main>
          <Switch>
            <Route path='/:region/trial' exact>
              <EmailPage/>
            </Route>
            <Route path='/:region/new-trial' exact>
              <NewTrialEmailPage/>
            </Route>
            <Route path='/:region/trial/duplicate' exact>
              <DuplicateTrial/>
            </Route>
            <Route path='/:region/trial/confirmation' exact>
              <TrialConfirmation/>
            </Route>
            <Route path='/:region/subscribe/payment'>
              <PaymentPage/>
            </Route>
            <Route path='/:region/subscribe'>
              <EmailPage/>
            </Route>
            <Route render={() => <Redirect to='/error' />} />
          </Switch>
        </Main>
      </Switch>
    </>
  );
};

export default App;

import React from 'react';
import {
  Box, Heading, Text, Spinner
} from '@myob/myob-widgets';
import laptopImage from 'assets/images/computer-screen.png';
import { useContentContext } from 'providers/contentProvider/ContentProvider';
import { ELEMENTS } from 'providers/contentProvider/Elements';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import ProgressButton from 'components/confirmation/progressButton/ProgressButton';
import { useConfirmationContent } from 'hooks/useConfirmationContent/useConfirmationContent';
import style from './DefaultConfirmation.module.scss';
import { ConfirmationProps } from '../types';
import config from '../../../../Config';
import {
  ANALYTICS_ID, ARL_DOWNLOAD_URL, ARL_PRODUCT_IDS, SUBSCRIPTION_TYPE
} from '../../../../constants';

const trialContent = (
  <Box className={style.trialContent}>
    <img className='confirmation-page-main-image' src={laptopImage} alt='desktop'/>
    <div className='confirmation-page-main-image-desc'>
      <p><b>Access your product</b></p>
      <p>Learn the ropes with the help of your own personalised setup activities</p>
    </div>
  </Box>
);

const useGetContent = () => {
  const {
    subscriptionType
  } = useSessionContext();
  const description = useConfirmationContent();
  if (subscriptionType === SUBSCRIPTION_TYPE.SUBSCRIBE) {
    return (
      <Box className={style.subscribeContent}>
        <Text fontWeight='inherit'>{description.header}</Text>
        {description.body && <Text fontWeight='inherit'>{description.body}</Text>}
        <Text fontWeight='inherit'>
          {description.footer}
          {description.link && <a href={description.link} target='_blank' rel='noopener noreferrer'>
            {description.link}</a>}
        </Text>
      </Box>
    );
  }
  return trialContent;
};

export const DefaultConfirmation: React.FC<ConfirmationProps> =
  ({
    isLoading
  }) => {
    const { getLabels } = useContentContext();
    const { productId } = useSessionContext();
    const pageTitle = getLabels(ELEMENTS.CONFIRMATION_PAGE_TITLE);
    const content = useGetContent();
    const endText =
        ARL_PRODUCT_IDS.includes(productId) ? 'Download MYOB AccountRight' : 'Access your software now';
    const buttonLink = ARL_PRODUCT_IDS.includes(productId) ?
      ARL_DOWNLOAD_URL : config.PRODUCT_URL;
    return (
      <Box className={style.container}>
        <Heading level={1} className={style.heading}>
          {pageTitle}
        </Heading>
        <Box className={style.content}>
          {isLoading && <Spinner size='small' data-testid='spinner'/>}
          {!isLoading && (
          <>
            {content}
            <ProgressButton
              id={ANALYTICS_ID.BUTTON_ACCESS_SOFTWARE}
              startText='Setting up your software'
              endText={endText}
              duration={config.DEEP_LINKING.BUTTON_DISABLE_TIME}
              href={buttonLink}
            />
          </>
        )}
        </Box>
      </Box>
    );
  };

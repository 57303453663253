import React from 'react';
import { PricingMoleculeSolo } from './PricingMoleculeSolo';
import { PricingMoleculeDefault } from './PricingMoleculeDefault';
import { FEELIX_THEMES } from '../../../constants';
import { PricingMoleculeProps } from './types';

export const PricingMolecule =
  ({
    theme,
    ...props
  }: PricingMoleculeProps & { theme: string}) => (
    <>
      {
         theme === FEELIX_THEMES.SOLO ?
           <PricingMoleculeSolo {...props} /> :
           <PricingMoleculeDefault {...props} />
       }
    </>
  );

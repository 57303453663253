import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { ARL_PRODUCT_IDS } from '../../constants';

type UseConfirmationContentType = {
  header: string;
  body?: string;
  footer: string;
  link?: string;
};
const JB_HI_FI_PROMO_CODES = [
  'JBHIFI-12M-LITE',
  'JBHIFI-12M-PRO',
  'JBHIFI-12M-PAYROLL-ONLY'
];
const defaultContent = {
  header: 'The first payment may be invoiced on a pro rata basis depending on the day of the month you sign up.',
  body: 'MYOB issues invoices on the 1st of each month and also offers 7 day payment terms, ' +
      'this means that you\'ll receive your invoice on the 1st, and any payment will be debited ' +
      'from your nominated bank account or credit card 7 days later.',
  footer: 'Your subscription auto-renews until it is cancelled. You can cancel at any ' +
      'time within the \'Manage My Product\' section of \'My Account\'.'
};
const annualContent = {
  header: 'The first payment may be invoiced on a pro rata basis depending on when you sign up. ' +
  'Payments will be debited from your nominated bank account or credit card.',
  body: 'Your subscription will auto-renew until it is cancelled. ' +
      'You can cancel by visiting the \'Manage My Product\' section of \'My Account\'.',
  footer: 'For further billing or account support, visit ',
  link: 'https://www.myob.com/au/support'
};

const arlProductContent = {
  header: 'MYOB issues invoices on the 1st of each month and any payment will be debited ' +
      'from your nominated bank account or credit card on the same day. ' +
      'Billing will auto-renew each month. \n' +
      'The first payment may be invoiced on a pro rata basis depending on the day you sign up.',
  body: 'Next steps: \n' +
      '1. Download and install MYOB AccountRight \n' +
      '2. Create your account (check your email for your invitation) \n' +
      '3. Set up your file to start working on the desktop and in the browser (instructions with your serial number' +
      ' will be emailed to you)',
  footer: 'Check your email for tip and tricks to getting started with MYOB AccountRight.'
};

const JBHiFiContent = {
  header: 'You will receive a confirmation email with your MYOB Serial Number shortly.',
  body: undefined,
  footer: 'Your subscription auto-renews until it is cancelled. ' +
      'You can cancel within the \'Manage My Product\' section of \'My Account\'.'
};

export const useConfirmationContent = (): UseConfirmationContentType => {
  const { isAnnualPrice, promoCode, productId } = useSessionContext();
  if (promoCode && JB_HI_FI_PROMO_CODES.includes(promoCode)) {
    return JBHiFiContent;
  } if (ARL_PRODUCT_IDS.includes(productId)) {
    return arlProductContent;
  } if (isAnnualPrice) {
    return annualContent;
  }
  return defaultContent;
};

import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSessionContext } from 'contexts/sessionProvider/SessionProvider';
import { useExistingUserCheck } from '../../hooks/useExistingUserCheck/useExistingUserCheck';
import { useRecaptcha } from '../../hooks/useRecaptcha/useRecaptcha';
import { useGetProduct } from '../../hooks/useGetProduct/useGetProduct';
import {
  getPageHeaderTitle,
  getSubmitButtonTextOnAccountDetailsForm,
  getSubmitButtonTextOnEmailInputForm,
  trackCheckoutStarting
} from '../emailPage/EmailPage';
import { EmailInputForm } from '../emailInputForm/EmailInputForm';
import { NewTrialAccountDetailsForm } from '../newTrialAccountDetailsForm/NewTrialAccountDetailsForm';
import { FEELIX_THEMES, SUBSCRIPTION_TYPE } from '../../constants';

export const NewTrialEmailPage: React.FC = () => {
  const [emailValue, setEmailValue] = useState('');
  const {
    region,
    productFreePeriod,
    setHasPurchaseSubmitted,
    theme,
    setEmailChecked
  } = useSessionContext();
  setHasPurchaseSubmitted(false);
  const { isLoading: isProductLoading, isSuccess: fetchProductDataSuccess, productData } = useGetProduct();
  const {
    isNewUser, isEmailChecked, isError, identityId
  } = useExistingUserCheck(emailValue);
  setEmailChecked && setEmailChecked(isEmailChecked);
  trackCheckoutStarting(isEmailChecked, emailValue); // This function contains React Hooks, never put it in a condition.
  const {
    isLoading: isRecaptchaLoading,
    success: isRecaptchaSuccess
  } = useRecaptcha({ enabled: isEmailChecked, action: 'view', identityId });

  const isLoading = isRecaptchaLoading || isProductLoading;
  if (isLoading) {
    return (<div data-testid='email-page-loading'/>);
  }
  const isProductAndRegionMatched: boolean = fetchProductDataSuccess && productData && region === productData.region;
  if (isRecaptchaSuccess === false || !isProductAndRegionMatched) {
    return <Redirect to='/error' />;
  }

  const trialDealDuration = productData?.items[0]?.trialDeal?.duration ?? 1;
  const isSolo = theme === FEELIX_THEMES.SOLO;
  if (isEmailChecked) {
    return (
      <React.Fragment>
        <NewTrialAccountDetailsForm
          pageHeaderText={getPageHeaderTitle(SUBSCRIPTION_TYPE.SUBSCRIBE, productFreePeriod, trialDealDuration)}
          submitButtonText={getSubmitButtonTextOnAccountDetailsForm(SUBSCRIPTION_TYPE.SUBSCRIBE, isNewUser, isSolo)}
          isNewUser={isNewUser}
          emailValue={emailValue}
        />
      </React.Fragment>
    );
  }
  return (
    <EmailInputForm
      handleEmailChecked={setEmailValue}
      pagerHeaderTitle={getPageHeaderTitle(SUBSCRIPTION_TYPE.SUBSCRIBE, productFreePeriod, trialDealDuration)}
      submitButtonText={getSubmitButtonTextOnEmailInputForm(SUBSCRIPTION_TYPE.SUBSCRIBE)}
      isError={isError}
    />
  );
};

import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { useAuth0 } from 'hooks/Auth0/Auth0';

import '../Confirmation.scss';
import { useSendPurchaseEventAndOrderCompletedEvent, useContentViewed } from 'hooks/Analytics/useAnalytics';
import { useEnhancedPurchaseEvent, usePurchaseEvent } from 'hooks/GTMAnalytics/useGTMAnalytics';
import { usePurchaseEventGA4 } from 'hooks/GTM4Analytics/useGTMAnalytics';
import { useNinetailedCheckoutEvent } from 'hooks/useNinetailedAnalytics/useNinetailedAnalytics';
import { Confirmation as GenericConfirmation } from 'components/generic';
import { useCheckout } from 'hooks/useCheckout/useCheckout';
import { CheckoutProps } from 'hooks/useCheckout/types';
import { useSendEventToSegmentForPurchase } from '../useSendEventToSegmentForPurchase';
import {
  ANALYTICS_ID,
  GTM_ANALYTICS_CONSTANTS,
  NINETAILED_ANALYTICS_CONSTANTS
} from '../../../constants';

function useTrackSubscribeConfirmationPage(email: string | undefined) {
  useContentViewed({
    elementId: ANALYTICS_ID.CONTENT_VIEWED_SUBSCRIBE_CONFIRMATION
  });
  useSendPurchaseEventAndOrderCompletedEvent();
  usePurchaseEventGA4();
  usePurchaseEvent(GTM_ANALYTICS_CONSTANTS.PURCHASE_SEGMENT);
  useSendEventToSegmentForPurchase();
  useEnhancedPurchaseEvent(email);
  // Ninetailed track event for checkout with payment
  useNinetailedCheckoutEvent(NINETAILED_ANALYTICS_CONSTANTS.PURCHASE);
}

export const SubscribeConfirmation: React.FC = () => {
  const { user, isLoading: isAuth0Loading, isAuthenticated } = useAuth0();
  const location = useLocation<CheckoutProps>();

  useTrackSubscribeConfirmationPage(user?.email);
  const locationState = location.state;
  const {
    isError,
    isLoading: isCheckoutLoading
  } = useCheckout(locationState);

  const isLoading = isAuth0Loading || isCheckoutLoading;
  if (!isLoading && (!isAuthenticated || isError)) {
    return <Redirect to={'/error'}/>;
  }

  return <GenericConfirmation isLoading={isLoading}/>;
};

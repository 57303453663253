import React from 'react';
import soloStyle from './BackgroundImage.solo.module.scss';
import defaultStyle from './BackgroundImage.module.scss';
import { FEELIX_THEMES } from '../../constants';

const getStyle = (theme: string) => {
  if (theme === FEELIX_THEMES.SOLO) {
    return soloStyle;
  }
  return defaultStyle;
};

interface BackgroundImageProps {
  theme: string;
}

export const BackgroundImage: React.FC<BackgroundImageProps> =
  ({ theme }) => {
    const style = getStyle(theme);
    return (
      <div
        className={style.backgroundImage}
      />
    );
  };
